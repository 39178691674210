'use client';

// Error components must be Client Components

import Recommendations from '@/react/components/product-list/recommendation/Recommendations';
import { ErrorPageType, PageError } from '@/react/components/PageError';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { type IPage, PageType } from '@/services/models/Page';
import { UserInteraction } from '@/react/components/core-ui/UserInteraction';
import { PageComponent } from '@/react/components/user-action/PageComponent';
import { PageService } from '@/services/isomorphic/PageService';

import { RecommendationContext } from '@/configs/recommendations';

import S from './error.module.scss';

/**
 * Displays fallback UI when runtime errors occur.
 * @see https://nextjs.org/docs/app/api-reference/file-conventions/error
 */
const Error = ({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}): JSX.Element => {
  const page: IPage = { url: '/503', pageType: PageType.Unavailable };
  PageService.page = page;

  return (
    <>
      <PageComponent page={PageService.page} />
      <UserInteraction interaction={{ action: EventType.PageView, page }} />
      <PageError pageType={ErrorPageType.NotAvailable} />
      <div className={S.products}>
        <Recommendations context={RecommendationContext.NotFoundPage} />
      </div>
    </>
  );
};

export default Error;
